import React, { forwardRef } from 'react';
import { Typography } from '@mui/material';
import './Product.css';

const Product = forwardRef((props, ref) => {
  return (
    <>
      <div className="two-column-container">
        {/* Global Forecasting */}
        <div className="text-column">
          <Typography variant="h4">Global Forecasts Exploiting Geospatial Correlations</Typography>
          <Typography>
            Serana's state-of-the-art AI technology takes into account geospatial correlations across the globe to enhance forecast accuracy.
          </Typography>
        </div>
        <div className="image-column">
          <img src="./assets/world.gif" alt="Forecasting that take into account geospatial correlations"  width="100%" />
        </div>
      </div>

      {/* Time horizons */}
      <div className="two-column-container">
        <div className="image-column">
          <img src="./assets/forecasting.gif" alt="Forecasting" width="100%" />
        </div>
        <div className="text-column">
          <Typography variant="h4">Precise Forecasts at Customized Time Horizons</Typography>
          <Typography>
            Our advanced Machine Learning modelling techniques enables accurate forecasts to feed your planning and management systems - tomorrow's insights today!
          </Typography>
        </div>
      </div>

      {/* Data connectors */}
      <div className="two-column-container">
        <div className="text-column">
          <Typography variant="h4">Ingest and Export Through a Variety of Connectors</Typography>
          <Typography>
            Ingest data using a variety of connectors including REST API, streaming sources like Kafka and object stores like AWS S3. Export forecasts through a REST endpoint or visualize on the web.
          </Typography>
        </div>
        <div className="image-column">
          <img src="./assets/connectors.png" alt="Support for various ingest and export connectors" width="80%" />
        </div>
      </div>
      <div className='text-centered'>
        <Typography variant="h4">Unlock the Power of Serana AI Forecast</Typography>
        <Typography component="ul">
          <li className='product-li'>Master advanced AI for unparalleled time series forecasting</li>
          <li className='product-li'>Harness state-of-the-art models, seamlessly ingesting data from diverse sources</li>
          <li className='product-li'>Visualize your forecasts dynamically on an interactive geospatial map</li>
          <li className='product-li'>Effortlessly export forecasts to integrate with various planning and management systems</li>
        </Typography>
      </div>

    </>
  );
});

export default Product;