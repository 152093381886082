import React from "react";
import UpdateIcon from '@mui/icons-material/Update';
import c from "./ShowBlog.module.css";
import ToText from "../../utils/ToText";
import moment from "moment";
import { Typography } from "@mui/material";

function ShowBlog(props) {
  return (
    <div className={`col-md-4 col-sm-6 col-xs-12 ${c.grid}`}>
      <div className={c.cardsmall}>
        <a
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div
            className={c.cardpost__image}
            style={{ backgroundImage: `url(${props.thumbnail})` }}
          ></div>
          <div className={c.cardpost__overlay}></div>
        </a>
        <div className="card-body">
          <div className={c.blogContentContainer}>
            <h5 className="card-title">
              <a
                href={props.link} 
                target="_blank"
                rel="noopener noreferrer"
                className={c.textfiordblue}
              >
                <Typography variant="h4">{props.title}</Typography>
              </a>
            </h5>
            <span className={`${c.textMuted} text-muted`}>
            <UpdateIcon />
            {moment(props.pubDate).format("MMM DD, YYYY")}
          </span>

            <p className={c.cardText}>
              <Typography>
                {`${ToText(
                  props.description.substring(0, 1000)
                )}...`}
              </Typography>
            </p>
          </div>

          
        </div>
      </div>
    </div>
  );
}

export default ShowBlog;
