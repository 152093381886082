import React, { useEffect, useState } from 'react';
import { Select, MenuItem} from '@mui/material';

function SpatialDropDown({ onSelectChange, options, reload }) {  
  const [selectedDescription, setSelectedDescription] = useState('');
  
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDescription(selectedValue);
    onSelectChange(selectedValue);
  };
  
  useEffect(() => {
    if (!options.includes(selectedDescription)) {
      setSelectedDescription('');
    }
  }, [options, reload, selectedDescription]);

  return (
      <Select
        value={selectedDescription}
        onChange={handleSelectChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="" disabled >
          Select Spatial ID
        </MenuItem>
        {options.map((row, index) => (
          <MenuItem key={index} value={row}>
            {'Spatial ID: ' + row}
          </MenuItem>
        ))}
      </Select>
  );
}

export default SpatialDropDown;
