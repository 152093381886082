import React, { useState, useEffect } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { TASK_URL } from '../../api';
import useToken from '../../../useToken';
import SpatialDropDown from './SpatialDropDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';


const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const month = date.toLocaleDateString('en-US', { month: 'short' });
  const day = date.getDate();
  const hour = date.getHours();
  return `${month} ${day} ${hour}h`;
};

export default function Chart({ taskId, reload, toggleReload }) {
  const [forecastData, setForecastData] = useState();
  const [historicalData, setHistoricalData] = useState();
  const [data, setData] = useState();
  const [spatialIds, setSpatialIds] = useState([]);
  const [selectedSpatialId, setSelectedSpatialId] = useState("")
  const { token } = useToken();

  // Function to merge data based on timestamp
  const mergeData = (spatial_id) => {
    const combinedData = [];
    const fd = forecastData?.find(forecast => forecast.spatial_id === spatial_id)?.data || []
    const hd = (historicalData ?? []).filter(row => row.spatial_id === spatial_id);

    // // Add forecast data to combinedData
    fd.forEach((entry) => {
      const timestamp = entry.timestamp;
      const existingEntry = combinedData.find((item) => item.timestamp === timestamp);

      if (existingEntry) {
        existingEntry.forcasted = entry.value;
      } else {
        combinedData.push({ spatial_id, timestamp, forcasted: entry.value, hist: null });
      }
    });

    // Add historical data to combinedData
    hd.forEach((entry) => {
      const timestamp = entry.timestamp;
      const existingEntry = combinedData.find((item) => item.timestamp === timestamp);

      if (existingEntry) {
        existingEntry.hist = entry.value;
      } else {
        combinedData.push({ timestamp, forcasted: null, hist: entry.value });
      }
    });
    combinedData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
    return combinedData;
  };

  useEffect(() => {
    if (taskId) {
      setData([]);
      fetchPredictionData(taskId, token);
      fetchHistoricalData(taskId, token);
    }
  }, [taskId, token]);

  useEffect(() => {
    if (taskId) {
      setForecastData([]);
      setHistoricalData([]);
      fetchPredictionData(taskId, token);
      fetchHistoricalData(taskId, token);
    }
  }, [reload]);


  useEffect(() => {
    if (taskId) {
      setData(mergeData(selectedSpatialId));
      console.log('Reload effect in chart')
    }
  }, [historicalData, forecastData]);
  


  const fetchPredictionData = async (taskId, accessToken) => {
    try {
      const response = await fetch(`${TASK_URL}predict?task_id=${taskId}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${accessToken?.access_token}` }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch prediction data');
      }
      const predictionData = await response.json();
      if (predictionData?.forecasts.length > 0) {
        setForecastData(predictionData.forecasts)
        console.log('Forecasts fetched successfully')
      }
    } catch (error) {
      console.error('Error fetching prediction data:', error);
    }
  };

  const fetchHistoricalData = async (taskId, accessToken) => {
    try {
      const response = await fetch(`${TASK_URL}observe?task_id=${taskId}`, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${accessToken?.access_token}` }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch observation data');
      }
      const historicalData = await response.json();

      if (historicalData?.length > 0) {
        setHistoricalData(historicalData)
        setSpatialIds([...new Set(historicalData.map(sample => sample.spatial_id))]);
        console.log('Historical data fetched successfully')
      }
    } catch (error) {
      console.error('Error fetching historical data:', error);
    }
  };

  const handleSpatialIdSelectChange = (spatialId) => {
    setSelectedSpatialId(spatialId);
    setData(mergeData(spatialId));
  };

  return (
    <div className="chart-container">
      <div className="chart-header">
        <SpatialDropDown onSelectChange={handleSpatialIdSelectChange} options={spatialIds} reload={reload}/>
        <Button onClick={() => toggleReload()}><RefreshIcon /></Button>
      </div>
      <LineChart
        width={1000}
        height={450}
        data={data ?? []}
        margin={{ top: 25, right: 30, bottom: 30, left: 120 }}>
        <Line type="monotone" dataKey="hist" stroke="#8884FF" strokeWidth="2px" name="Observed" dot={false} strokeDasharray="5 3"/>
        <Line type="monotone" dataKey="forcasted" stroke="#11FF11" strokeWidth="2px" name="Forecasted" dot={false} />
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis
          dataKey="timestamp"
          tick={{
            angle: -45,
            textAnchor: 'end',
            fontSize: 12,
          }}
          tickFormatter={formatTimestamp}
        />
        <YAxis domain={['auto', 'auto']} />
        <Tooltip />
        <Legend align="right" verticalAlign="top"/>
      </LineChart>
    </div>
  );
}