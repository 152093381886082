import React, { useState, useEffect } from 'react';
import { AppBar, Tab, Tabs, Toolbar, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [tabValue, setValue] = useState(0);
  const [showTabs, setShowTabs] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen width is less than or equal to 600px
      const isSmallScreen = window.innerWidth <= 600;
      setShowTabs(!isSmallScreen);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial check on component mount
    handleResize();

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <AppBar className='header' sx={{ background: '#0E5AFE', position: 'sticky'  }}>
        <Toolbar>
          <a href='/'>
            <img
              className='serana-logo'
              src='./assets/logo_transparent.png'
              alt='Serana AI Logo'
            />
          </a>
          {showTabs ? (
            <Tabs className='tabs' value={tabValue} onChange={handleChange}>
              <Tab
                label='Home'
                component={Link}
                to='/'
                className={`tab-link ${tabValue === 0 ? 'active-tab' : ''}`}
              />
              <Tab
                label='Blog'
                component={Link}
                to='/blog'
                className={`tab-link ${tabValue === 1 ? 'active-tab' : ''}`}
              />
              <Tab
                label='Dashboard'
                component={Link}
                to='/dashboard'
                className={`tab-link ${tabValue === 2 ? 'active-tab' : ''}`}
              />
            </Tabs>
          ) : (
            <React.Fragment>
              <IconButton
                className='menu-icon'
                color='inherit'
                onClick={handleMenuClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  component={Link}
                  to='/'
                  onClick={() => {
                    handleMenuClose();
                    setValue(0);
                  }}
                >
                  Home
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/blog'
                  onClick={() => {
                    handleMenuClose();
                    setValue(1);
                  }}
                >
                  Blog
                </MenuItem>
                <MenuItem
                  component={Link}
                  to='/dashboard'
                  onClick={() => {
                    handleMenuClose();
                    setValue(2);
                  }}
                >
                  Dashboard
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
