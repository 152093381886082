import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { TASK_URL } from '../../api';
import useToken from '../../../useToken';
import './CreateTask.css';


export default function CreateTask() {
  const { token } = useToken();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [storageSource, setStorageSource] = useState('');
  const [streamingSource, setStreamingSource] = useState('');
  const [streamingDestination, setStreamingDestination] = useState('');
  const [creationFailureMessage, setCreationFailureMessage] = useState('');
  const [creationSuccessMessage, setCreationSuccessMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = JSON.stringify({
      title: title,
      description: description,
      storage_source: storageSource,
      streaming_source: streamingSource,
      streaming_destination: streamingDestination
    });

    fetch(TASK_URL, {
      method: 'POST',
      body: formData,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token?.access_token}`
      },
    })
      .then((response) => {
        if (!response.ok) {
          setCreationFailureMessage("Task Creation failed.");
          throw new Error('Task Creation failed.' + response);
        }
        return response.json();
      })
      .then(() => {
        setCreationSuccessMessage("Task created successfully!");
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
  };

  useEffect(() => {
    if (creationSuccessMessage) {
      console.log('Task Creation successful');
      navigate('/dashboard');
    }
  }, [creationSuccessMessage, navigate]);


  return (
    <Container component="main" maxWidth="xs" className='create-task-container' >
      <div>
        <Typography variant="h5">
          Create Spatio-temporal Model
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title"
            label="Title"
            name="title"
            autoFocus
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="description"
            label="Description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="storageSource"
            label="Storage Source"
            name="storageSource"
            value={storageSource}
            onChange={(e) => setStorageSource(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="streamingSource"
            label="Streaming Source"
            name="streamingSource"
            value={streamingSource}
            onChange={(e) => setStreamingSource(e.target.value)}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="streamingDestination"
            label="Streaming Destination"
            name="streamingDestination"
            value={streamingDestination}
            onChange={(e) => setStreamingDestination(e.target.value)}
          />

          <Button type="submit" variant="contained">
            CREATE
          </Button>
        </form>
        {creationFailureMessage && <Typography variant="body2" color="error">{creationFailureMessage}</Typography>}
        {creationSuccessMessage && <Typography variant="body2" color="success">{creationSuccessMessage}</Typography>}
      </div>
    </Container>
  );
};
