import React, { useEffect, useState } from 'react';
import { Select, MenuItem, CircularProgress } from '@mui/material';
import { TASK_URL } from '../../api';
import useToken from '../../../useToken';

function TaskDropDown({ onSelectChange }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { token } = useToken();
  const [error, setError] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(TASK_URL, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${token?.access_token}` }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch tasks');
        }

        const jsonData = await response.json();
        setData(Array.isArray(jsonData) ? jsonData : [jsonData]);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDescription(selectedValue);
    onSelectChange(selectedValue);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    
      <Select
        value={selectedDescription}
        onChange={handleSelectChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        
      >
        <MenuItem value="" disabled>
          Select Task
        </MenuItem>
        {data.map((row) => (
          <MenuItem key={row.id} value={row.id}>
            {row.title + ' ID: ' + row.id}
          </MenuItem>
        ))}
      </Select>
    
  );
}

export default TaskDropDown;