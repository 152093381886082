import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Import ThemeProvider and createTheme
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = createTheme(); // Create your MUI theme
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}> {/* Provide the theme */}
      <App />
    </ThemeProvider>
  // </React.StrictMode>,
);
reportWebVitals();