import React, { useState, forwardRef } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { TextField, Button } from '@mui/material';
import './Subscribe.css';

const Subscribe = forwardRef((props, ref) => {
  const url = `https://serana.us21.list-manage.com/subscribe/post?u=ecb5c05357ff6f932df5752f9&id=6fea215793`;
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const CustomForm = ({ status, message, onValidated }) => {
    let email;

    const handleSubmit = (e) => {
      e.preventDefault();

      if (email && email.value.indexOf('@') > -1) {
        onValidated({ EMAIL: email.value });
      }
    };

    return (
      <div className="subscribe-form" ref={ref}>
        {status !== 'success' ? (
          <form onSubmit={(e) => handleSubmit(e)}>
            <TextField
              inputRef={(node) => (email = node)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              className="custom-input-field"
            />
            <Button type="submit" variant="contained" color="primary">
              GET STARTED
            </Button>
          </form>
        ) : null}
        {status === 'sending' && <p>Sending...</p>}
        {status === 'error' && (
          <p dangerouslySetInnerHTML={{ __html: message }} />
        )}
        {status === 'success' && (
          <div>
            <p>Thank you! We will get in touch shortly!</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="subscribe-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={subscriptionStatus || status}
            message={message}
            onValidated={(formData) => {
              subscribe(formData);
              setSubscriptionStatus('success');
            }}
          />
        )}
      />
    </div>
  );
});

export default Subscribe;
