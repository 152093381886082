import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin,faYoutube, faGithub } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer>
      <div className="row">
        <div className="col">
          <img src="./assets/transparent-logo.png" alt="Serana Logo" className="logo" />
        </div>
        <div className="col">
          <h3>
            Office
            <div >
              <span></span>
            </div>
          </h3>
          <p>Vancouver BC</p>
          <p className="email-id">info@serana.ai</p>
        </div>
        <div className="col">
          <h3>
            Follow Us
            <div >
              <span></span>
            </div>
          </h3>
          <div className="social-icons">
            <a href="https://www.linkedin.com/your-profile" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://www.youtube.com/your-channel" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a href="https://github.com/your-username" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </div>
        <div className="col">
          <h3>
            Links
            <div >
              <span></span>
            </div>
          </h3>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/login">Login</a></li>
          </ul>
        </div>
        
      </div>
      <hr />
      <p className="copyright">&copy; Serana AI {new Date().getFullYear()}</p>
    </footer>
  );
};

export default Footer;