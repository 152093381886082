import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LOGIN_URL } from '../api';
import PropTypes from 'prop-types';
import './Login.css';

export const Login = ({ setToken }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailedMessage, setLoginFailedMessage] = useState('');

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new URLSearchParams({
      username: email,
      password: password,
    });

    fetch(LOGIN_URL + '/access-token', {
      method: 'POST',
      body: formData.toString(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        setLoginFailedMessage("Login failed. Please check your credentials.");
        if (!response.ok) {
          throw new Error('Login failed');
        }
        return response.json();
      })
      .then((token) => {
        setToken(token);
        console.log('Login successful');
        navigate('/dashboard');
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs" className="login-container">
    <div>
      <Typography variant="h4" className="login-heading">
        Login
      </Typography>
      <Typography variant="body1" className="login-description">
      Please enter your e-mail and password.
        </Typography>
      <form onSubmit={handleSubmit} className="login-form">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
        <Button type="submit" variant="contained" fullWidth className="login-button">
          Login
        </Button>
      </form>
      {loginFailedMessage && <Typography variant="body2" color="error" className="login-error">{loginFailedMessage}</Typography>}
      <Typography variant="body2" className="login-info">Request an account at info@serana.ai</Typography>
    </div>
   
    <br/>
    
  </Container>
);
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
}; 