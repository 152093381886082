import React, { Component } from "react";
import Axios from "axios";
import ShowBlog from "../ShowBlog/ShowBlog";
import Spinner from "../Spinner/Spinner";
import './Blog.css';
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";

export class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        ptitle: "",
        name: "",
        avtar: "",
        profileurl: "",
      },
      item: [],
      isloading: true,
      error: null
    };
  }
  mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@serana.ai";

  componentDidMount() {
    Axios.get(this.mediumURL)
      .then((data) => {
        const avatar = data.data.feed.image;
        const profileLink = data.data.feed.link;
        const res = data.data.items;
        const posts = res.map(post => {
          return {
            ...post,
            thumbnail: this.extractThumbnail(post.content) || ""
          };
        });

        const title = data.data.feed.title;
        this.setState({
          profile: {
            ptitle: title,
            profileurl: profileLink,
            avtar: avatar,
          },
          item: posts,
          isloading: false,
          error: null
        });
      })
      .catch((e) => {
        this.setState({
          error: e.toJSON(),
          isloading: false
        });
        console.log(e);
      });
  }

  // Add a helper function to extract thumbnail from content
  extractThumbnail(content) {
    const doc = new DOMParser().parseFromString(content, 'text/html');
    const imgElement = doc.querySelector('img');
    return imgElement ? imgElement.src : null;
  }


  render() {
    let post;

    if (this.state.item) {
      post = this.state.item.map((post, index) => (
        <Grid key={index} item xs={6} className="blog-item">
          <ShowBlog
            {...post}
            {...this.state.profile}
            content={post.description} // Pass content instead of description
          />
        </Grid>
      ));
    }

    if (this.state.isloading) {
      post = <Spinner />;
    }

    if (this.state.error) {
      let error = this.state.error.code ? this.state.error.code : this.state.error.name;
      let errorMsg = this.state.error.message;
      post = (
        <>
          <h2 className="red center">{error}</h2>
          <p className="errorMessage center">{errorMsg}</p>
        </>
      );
    }

    return (
      <div className="page-container">
      <div className="blog-header">
          
        </div>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            {post}
          </Grid>
        </Container>
      </div>
    );
  }
}

export default Blog;