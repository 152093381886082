import React from 'react';
import Home from './components/Home/Home';
import { Blog } from './components/Blog/Blog';
import { Login } from './components/Login/Login';
import { Dashboard } from './components/Dashboard/Dashboard';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import useToken from './useToken';
import Footer from './components/Footer/Footer';
import './App.css';


function App() {
  const { setToken } = useToken();
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      <Footer />
    </Router>

  );
}

export default App;
