
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import useMediaQuery from '@mui/material/useMediaQuery';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreateIcon from '@mui/icons-material/Create';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import useToken from '../../../useToken';

const drawerWidth = 220;

const MaterialUISidebar = ({ handleTabChange }) => {
  const { setToken } = useToken();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };

  const handleConfirmLogout = () => {
    setToken(null);
    console.log('Logout successful');
    navigate('/login');
    setLogoutDialogOpen(false);
  };

  const handleCancelLogout = () => {
    setLogoutDialogOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', marginLeft: isSmallScreen ? '0' : `-${drawerWidth}px` }}>
      <Drawer
        variant={isSmallScreen ? 'temporary' : 'permanent'}
        anchor="left"
        open={!isSmallScreen}
        onClose={() => {}}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#0E5AFE',
            color: 'white',
            marginTop: '60px', /* Adjust this margin based on your design */
            zIndex: 0,
          },
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleTabChange('dashboard')}>
              <ListItemIcon sx={{ color: 'white' }}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="DASHBOARD" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleTabChange('visualization')}>
              <ListItemIcon sx={{ color: 'white' }}>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="STATUS" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleTabChange('create')}>
              <ListItemIcon sx={{ color: 'white' }}>
                <CreateIcon />
              </ListItemIcon>
              <ListItemText primary="CREATE MODEL" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon sx={{ color: 'white' }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="LOGOUT" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Dialog open={logoutDialogOpen} onClose={handleCancelLogout}>
        <DialogTitle>Are you sure you want to Logout? </DialogTitle>
        <DialogActions>
          <Button onClick={handleCancelLogout}>Cancel</Button>
          <Button onClick={handleConfirmLogout} autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
    

export default MaterialUISidebar;