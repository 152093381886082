import React, { useRef, useEffect } from 'react';
import { Container } from '@mui/material';

import Subscribe from '../Subscribe/Subscribe';
import Banner from './Banner';
import Product from '../Product/Product';

export function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const subscribeRef = useRef(null);
  const scrollToSubscribe = () => {
    console.log("scroll to subscribe")
    if (subscribeRef.current) {
      subscribeRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const productRef = useRef(null);

  return (
    <div className='body-content'>
      <Banner scrollToSubscribe={scrollToSubscribe}/>
      <Container maxWidth="lg">
      <Product ref={productRef}/>
      <Subscribe ref={subscribeRef}/>
      </Container>
    </div>
  );
}

export default Home;