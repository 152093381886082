import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import './Home.css';


export function Banner({ scrollToSubscribe }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageTexts = [
    {
      src: '/assets/hero5.jpg',
      alt: 'Forecasting in time and space',
      text: "Where Time Meets Space: Tomorrow's Insights Today"
    },
    {
      src: '/assets/hero6.jpg',
      alt: 'SatCom',
      text: "SatCom Mastery: Amplify Efficiency with Future Insight"
    },
    {
      src: '/assets/hero2.jpg',
      alt: 'SmartGrids',
      text: "Empower Smart Grids: Forecast Energy Demands Ahead of Time"
    },
  ];

  useEffect(() => {
    const nextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageTexts.length);
    };

    const slideshowInterval = setInterval(nextImage, 5000); // Change image every 5 seconds

    return () => clearInterval(slideshowInterval);
  }, [imageTexts.length]);


  return (
    <div>
        <div className="slideshow-container">
          <div className="slideshow-content">
            <img
              id="slideshow-image"
              className="slideshow-image"
              src={imageTexts[currentImageIndex].src}
              alt={imageTexts[currentImageIndex].alt}
            />
            <div className="image-overlay">
              <div className="centered-text-container">
                <Typography
                  variant="h1"
                  className="constant-header"
                  style={{
                    fontSize: '3.8rem',
                    fontWeight: 'bold',
                    color: '#ffffff',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    paddingBottom: '4.2rem',
                  }}
                >
                  Serana AI - Spatiotemporal Forecasting<br />
                </Typography>
                <Typography
                  variant="h4"
                  className="dynamic-header"
                  style={{
                    fontSize: '2rem',
                    color: '#ffffff',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    paddingBottom: '2rem',
                  }}
                >
                  {imageTexts[currentImageIndex].text}
                </Typography>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={scrollToSubscribe}
                  className="get-started-button"
                  style={{
                    padding: '10px 20px',
                    fontSize: '20px',
                    marginTop: '20px',
                    border: '1px solid white', // Set the border color to white
                    color: 'white',
                  }}
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
}
export default Banner;