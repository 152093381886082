import React, { useState } from 'react';
import TaskDropDown from './TaskDropDown';
import TaskDetails from './TaskDetails';


export default function Task() {
  const [selectedTaskId, setSelectedTaskId] = useState('');

  const handleTaskSelectChange = (taskId) => {
    setSelectedTaskId(taskId);
  };

  return (
    <div className="chart-container">
      <TaskDropDown onSelectChange={handleTaskSelectChange} />
      {selectedTaskId && <TaskDetails taskId={selectedTaskId} />}
    </div>
  );
}